<script>


/**
 * Cheque Print  component
 */


import {
    required,numeric
} from "vuelidate/lib/validators";

import Choices from "choices.js";
import {printer_list} from '@/api/misc/printer'
import {getArApApi, getCashApi} from '@/api/cash'
import simplebar from "simplebar-vue";
import { financial } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

    props: ['transactions', 'bank_account'],
    components: {
        simplebar
    },

    validations: {
        cheque_id : {required, numeric},
    },



    data() {

        return {
            submitted: false,
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            bank_account_id : '',
            total_amount   : 0,
            cheque_choice  : {},
            cheque_id      : '',
            printer_id     : '',
            printer_list   : [],
            printer_choice : {},
            cheque_list    : [],
            wait_for_job   : false,
            formed_trs     : [],
        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            
            if (this.$v.$invalid == false) {
                let data = {
                    printer_id   : this.printer_id,
                    transactions : this.formed_trs,
                    bank_account : this.bank_account_id,
                }
               // this.wait_for_job = true
                getArApApi().print_transactions_cheque(data).then(res => {
                    if (res.errCode != 0) {
                        this.$alertify.error("Failed to Send Print Job " + res.errCode);
                       // this.wait_for_job = false
                    } else {
                        this.update_trans_job(res.data)
                        this.$alertify.error("Setup Cheque Print Job Success ");
                        this.$emit('confirm')
                    }

                    this.submitted = false
                })
               
            }
        },


        update_trans_job(t_list) {
            this.transactions.push({})
            this.transactions.map(e => {
                let t1 = t_list.find(e1 => e1.id == e.id)
                if (t1) {
                    e.job_id = t1.job.id
                }
            })
            this.transactions.splice(this.transactions.length - 1, 1)
           
        },

        onGrouped(evt) {
            if (evt.target.checked == false) {
                this.formed_trs = this.transactions
                this.re_calculate_cheque()
                return
            }
            
            this.formed_trs = []
            this.transactions.map(t => {

                let key = t.payee_type +'_'+t.payee

                let group = this.formed_trs.find(e => e.key == key)
                if (group) {
                    group.amount += t.amount
                    group.trs.push(t)
                } else {
                    this.formed_trs.push({
                        payee        : t.payee,
                        payee_type   : t.payee_type,
                        address      : t.address,
                        key          : key,
                        amount       : t.amount,
                        trs          : [t],
                        is_groupd    : true
                    })
                }



            })

            this.re_calculate_cheque()
        },


        re_calculate_cheque() {
            let start_number = Number(this.cheque_id)
            this.formed_trs.map(e => {e.cheque = String(start_number).padStart(6, '0'); start_number ++})
        }

    },

    created() {


    },

    mounted() {

        this.bank_account_choice = new Choices('#choices-bank-account', { })
        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                  
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b,
                        selected : b.name == this.bank_account? true:false
                    })
                    if (b.name == this.bank_account) {
                         this.bank_account_id = b.id
                    }
                })

                this.bank_account_choice.setChoices(this.bank_account_list)
              
            }
        })

        this.total_amount = financial(this.transactions.reduce((a, c) =>a+c.amount, 0), 2)
        printer_list({}).then(res => {
            if (res.errCode == 0) {
                res.data.map(e => {
                    e.label = e.printer_name + ' ' + e.location +'('+e.status+')'
                    e.value = e.printer_id
                    this.printer_list.push(e)
                })

                this.printer_choice.setChoices(this.printer_list)
            }
        })
        this.printer_choice = new Choices('#printer_choice', {
            choices : this.printer_list
        });

        this.formed_trs = this.transactions
    },

    watch: {
        cheque_id(new_val) {
            let cheque_number = Number(new_val) 
            this.formed_trs.map(e => {e.cheque = String(cheque_number).padStart(6, '0'); cheque_number ++;})

            //this.formed_trs.map(e => e.cheque = (new_val ++))
            //this.transactions.map(e => e.cheque = (new_val ++))
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" :disabled="this.bank_account? true : false">
                            </select>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="choices-agent-input" class="form-label">Printer</label>
                            <select name="choices-multiple-remove-button" id="printer_choice"  v-model="printer_id" >
                  
                             </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    

                    <div class="col-md-3">
                        <div class="mb-3">

                            <label for="choices-agent-input" class="form-label">Start Cheque Number</label>
                            <input id="validationCustom01" v-model="cheque_id" type="text" class="form-control" maxlength="6"
                                placeholder="" value="" :class="{  'is-invalid': submitted && $v.cheque_id.$error, }" />
                            <div v-if="submitted && $v.cheque_id.$error" class="invalid-feedback">
                                <span v-if="!$v.cheque_id.required">This value is required.</span>
                                <span v-if="!$v.cheque_id.numeric" >This value should be digits.</span>
                           
                            </div>
                           

                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Total Amount</label>
                            <input id="validationCustom01" v-model="total_amount" type="text" class="form-control" readonly />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Cheque Count</label>
                            <input id="validationCustom01" v-model="formed_trs.length" type="text" class="form-control" readonly />
                        </div>
                    </div>

                </div>
                <!-- end row-->
                <div class="row">

                    <div class="col-md-4">
                        <input type="checkbox" class="form-check-control" @change="onGrouped" />Group Same Payee
                    </div>
                </div>

                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="formed_trs" :fields="['Payee','Amount', 'ChequeNumber','Job']" responsive="sm" :per-page="formed_trs.length" 
                            class="table-check">

                            
                                <template #cell(Amount)="data">
                                    {{ data.item.amount }}
                                </template>

                                <template #cell(Payee)="data">
                                    {{ data.item.payee }}
                                </template>
                                <template #cell(ChequeNumber)="data">
                                    {{ data.item.cheque }}
                                </template>
                                <template #cell(Job)="data">
                                    {{ data.item.job_id }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Close</b-button>
                            <b-button variant="primary" type="submit" :disabled="wait_for_job || submitted">Print</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>